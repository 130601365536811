<template>
    <div id="login">
        <div class="gauss"></div>
        <div class="login-content">
            <div class="login-panel">
                <div class="login-target">正在登录到 随手拍后台管理系统</div>
                <div class="inputs">
                    <a-input placeholder="用户名" v-model:value="userName" class="input">
                        <template #prefix>
                            <user-outlined type="user" />
                        </template>
                        <!-- <template #suffix>
                            <a-tooltip :title="isRegister ? '登陆' : '注册'">
                                <a @click="register">{{isRegister ? '登陆' : '注册'}}</a>
                            </a-tooltip>
                        </template> -->
                    </a-input>
                    <a-input placeholder="密码" type="password" v-model:value="password" class="input">
                        <template #prefix>
                            <lock-outlined />
                        </template>
                    </a-input>
                    <a-input v-if="isRegister" placeholder="重复密码" type="password" v-model:value="passwordRe" class="input" @change="passCheck">
                        <template #prefix>
                            <lock-outlined />
                        </template>
                    </a-input>
                    <span style="color: red; font-size: 12px;" v-if="passDifferent">两次密码不一致</span>
                </div>
                <a-button @click="submit" :disabled="isRegister && passDifferent" type="primary" style="width: 80%" :loading="loading">{{isRegister ? '注册' : '登录'}}</a-button>
            </div>
        </div>
    </div>
</template>

<script>
import { UserOutlined, LockOutlined } from "@ant-design/icons-vue";
import { message } from "ant-design-vue";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import axios from "axios";
import md5 from "md5";
export default {
    components: { UserOutlined, LockOutlined },
    methods: {},
    data() {
        return {};
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const userName = ref("");
        const password = ref("");
        const passwordRe = ref("");
        const isRegister = ref(false);
        const passDifferent = ref(false);
        const loading = ref(false);

        function register() {
            if (isRegister.value) {
                isRegister.value = false;
            } else {
                isRegister.value = true;
            }
        }

        function submit() {
            if (isRegister.value) {
                if (userName.value === "") {
                    message.error("用户名不能为空");
                    return;
                }
                if (password.value === "") {
                    message.error("密码不能为空");
                    return;
                }
                if (password.value !== passwordRe.value) {
                    message.error("两次密码不一致");
                    return;
                }
                loading.value = true;
                let pass = md5(password.value);
                axios.post("api/user-register", {
                        userName: userName.value,
                        password: pass,
                    }).then((res) => {
                        loading.value = false;
                        if (res.data.success) {
                            console.log("success");
                            message.success('注册成功');
                            isRegister.value = false;
                        } else {
                            message.error(res.data.msg);
                        }
                    });
            } else {
                let pass = md5(password.value);
                loading.value = true;
                axios.post('api/server/userLogin', {account: userName.value, password: pass}).then((res) => {
                // console.log(res);
                loading.value = false;
                if (res.data.errcode == 100) {
                    console.log('success');
                    store.dispatch({type: 'login', data: { userName: userName.value, userid: res.data.data.id, permission: res.data.data.power, region: res.data.data.region}});
                    router.push({name: 'Home'});
                } else {
                    message.error(res.data.msg);
                }
            });
            }
        }

        function passCheck() {
            if (password.value !== passwordRe.value) {
                passDifferent.value = true;
            } else {
                passDifferent.value = false;
            }
        }

        return {
            userName,
            password,
            passwordRe,
            isRegister,
            passDifferent,
            loading,
            register,
            submit,
            passCheck,
        };
    },
};
</script>

<style>
#login {
    width: 100%;
    height: 100%;
    /* background-color: rgb(127, 204, 255); */
    /* background: url("../../public/login_bg.jpeg") no-repeat fixed center; */
    background-size: cover;
    /* filter: blur(10px); */
    overflow: hidden;
}

/* #login::before {
    content: '';
    filter: blur(10px);
    position: absolute;
    left: -10px;
    top: -10px;
    right: -10px;
    bottom: -10px;
    background: url("../../public/login_bg.jpeg") no-repeat fixed center;
    background-size: cover;
    overflow: hidden;
} */

.gauss {
    position: fixed;
    left: -30px;
    top: -30px;
    bottom: -30px;
    right: -30px;
    filter: blur(10px);
    background: url("../../public/login_bg.jpeg") no-repeat fixed center;
    background-size: cover;
}

.login-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.login-panel {
    z-index: 1;
    width: 400px;
    height: 300px;
    padding: 2vh 0;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border-radius: 10px;
    box-shadow: #6d6d6d 0px 0px 10px;
}

.login-target {
    font-size: 2vh;
    color: #6d6d6d;
    font-weight: bold;
}

.inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
}

.input {
    margin-bottom: 10px;
}
</style>
